<template>
    <div class="outerBox">
		<div class="yAxisBox">
			<div class="yAxisLabel" v-for="(item, index) in yAxisLabels" :key="index" :style="{height: gridHeight+'%'}">
				<div class="yAxisInnerBox">{{item}}</div>
			</div>
		</div>
		<div class="graphBox">
			<div class="barChartArea">
				<div class="barChartBackground">
					<div class="chartGrid" v-for="(item, index) in yAxisLabels" :key="index" :style="{height: gridHeight+'%'}"></div>
				</div>
				<div class="barChartBox">
					<div class="barChartContainer" v-for="(item, index) in dataArr" :key="index" :style="{width: dataArr.length?(100/dataArr.length)+'%':'0'}">
						<active-number :class="item.value==maxValue?'maxNumber':'normalNumber'" :number="item.value"></active-number>
						<div class="bar" :style="{height: boxTopValue?(item.value*100/boxTopValue)+'%':'0'}"></div>
					</div>
				</div>
			</div>
			<div class="xAxisLabelBox">
				<div class="xAxisLabel" v-for="(item, index) in dataArr" :key="index" :style="{width: dataArr.length?(100/dataArr.length)+'%':'0'}">{{item.name}}</div>
			</div>
		</div>
		<div class="unit">{{unit}}</div>
	</div>
</template>

<style lang="less" scoped>
.outerBox {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	justify-content: flex-end;
	
	.unit{
		position: absolute;
		height: 1.574vh;
		line-height: 1.574vh;
		font-size: 1.3vh;
		font-family: 'pingfangSc', sans-serif;
		color: #fff;
		left: 0;
	}

	.yAxisBox {
		width: 5.9%;
		height: 88.83%;
		margin-right: 2%;
		display: flex;
		flex-direction: column-reverse;
		
		.yAxisLabel {
			width: 100%;
			
			.yAxisInnerBox {
				width: 100%;
				height: 100%;
				transform: translateY(50%);
				display: flex;
				justify-content: flex-end;
				align-items: center;
				font-size: 1.3vh;
				line-height: 1.3vh;
				color: #fff;
				font-family: 'robotoMedium', sans-serif;
				font-weight: 400;
			}
			
		}
		
	}
	.graphBox {
		width: 92%;
		height: 100%;
		
		.xAxisLabelBox {
			display: flex;
			justify-content: space-between;
			margin-top: 0.37vh;
			
			.xAxisLabel {
				color: #fff;
				height: 1.66vh;
				line-height: 1.66vh;
				font-size: 1.3vh;
				text-align: center;
				font-family: 'robotoMedium', sans-serif;
			}
		}
		
		.barChartArea {
			width: 100%;
			height: 88.83%;
			position: relative;
			
			.barChartBackground {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				
				.chartGrid {
					border-bottom: 0.5px rgba(243,184,5,0.2) dashed;
					width: 100%;
				}
			}
			
			.barChartBox {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-around;
				
				.barChartContainer {
					height: 100%;
					position: relative;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: center;
					
					.bar {
						background: linear-gradient(180deg, #f3b805 0%, rgba(243,184,5,0) 100%);
						width: 20.69%;
						border-radius: 3px 3px 0 0;
						box-shadow: 0px 0px 10px 0px rgba(255,208,59,0.2);
						transition: height 1.2s;
					}
					.normalNumber {
						font-family: 'dinProBold', sans-serif;
						font-weight: bold;
						color: #fff;
						font-size: 1.68vh;
						height: 1.68vh;
						line-height: 1.68vh;
						margin-bottom: 0.37vh;
					}
					.maxNumber {
						font-family: 'dinProBold', sans-serif;
						font-weight: bold;
						font-size: 1.68vh;
						height: 1.68vh;
						line-height: 1.68vh;
						background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						margin-bottom: 0.37vh;
					}
				}
			}
		}
		
	}

}
</style>

<script>
const activeNumber = () => import('@/views/components/activeNumber.vue');

export default {
	props:['unit','dataInput','tagName','keyName'],
	components: {
		activeNumber
	},
	data(){
		return {
			yAxisLabels: [],
			maxValue: 0,
			boxTopValue: 0,
			gridHeight: 100,
			dataArr: [{
				name: '加载中...',
				value: 0
			},{
				name: '加载中...',
				value: 0
			},{
				name: '加载中...',
				value: 0
			},{
				name: '加载中...',
				value: 0
			},{
				name: '加载中...',
				value: 0
			},{
				name: '加载中...',
				value: 0
			}]
		}
	},
	mounted(){
		this.tagname = this.tagName;
		this.keyIndex = this.keyName;
		this.setbarChart(this.dataInput);
		return false;
	},
	watch: {
		dataInput(d){
			this.setbarChart(d);
			return false;
		}
	},
	methods:{
		setbarChart(d){
			let max = 0;
			for(let i = 0;i < d.length;i++){
				let v = parseFloat(d[i].value);
				if(v > max){
					max = v;
				}
			}
			//处理纵坐标数值间隔
			let dis = max / 5;
			let m = 1;
			if(dis >= 1000){
				m = 1000;
			}
			dis -= dis%m;
			let c = dis*6 <= max ? 7 : 6; //背景虚线数
			let h = 100 / c; //背景虚线间隔（高度百分比）
			let yAxisLabels = [];
			for(let j = 0;j < c;j++){
				let y = dis * j;
				if(m == 1000 && y != 0){
					y /= 1000;
					y += 'k';
				}
				yAxisLabels.push(y);
			}
			this.maxValue = max;
			this.gridHeight = h;
			this.yAxisLabels = yAxisLabels;
			this.boxTopValue = dis*c;
			this.dataArr = d;
		}
	}
}
</script>

